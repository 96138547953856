import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Header from '../components/Header/subpage'

const ThankYouPage = ({ data }) => {
  const headerImage = data.wordpressAcfOptions.options.header_image.localFile.childImageSharp.fluid
  return (
    <Layout
      title="Thank You"
      seo={null}
      padding="80vh 0 0 0"
      nofooter
    >
      <article>
        <Header
          image={headerImage}
          title="Thank You"
          text="Thanks for getting in touch! Someone will respond to your enquiry as soon as possible."
          returnHome
        />     
      </article>
    </Layout>
  )
}

export default ThankYouPage

export const ThankYouPageQuery = graphql`
  query ThankYouHeader {
    wordpressAcfOptions {
      options {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`